th > span > button {
  font-size: 0.7rem !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

th > span > button > div {
  align-items: flex-end;
}

.table-test > div:nth-child(1) svg,
.table-test > div:nth-child(1) input {
  color: white !important;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.tss-j230wd-MUIDataTableToolbar-root.css-hyum1k-MuiToolbar-root {
  height: 74px;
}

.tss-1oc08no-MUIDataTableBodyCell-root.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon.datatables-noprint {
  text-align: right;
}


.MuiTablePagination-toolbar > div:nth-child(3) {
  background: linear-gradient(195deg, #49a3f1, #1a73e8);
  border-radius: 0.5rem;
  position: relative;
  top: -0.1rem;
}
.showMoreBtn {
  width: 100%;
}

.text-transform-none {
  text-transform: none;
}

/* #searchBox {
  transition-duration: 1s;
  transition-property: width;
  width: 20px;
  display: flex;
} */

/* .testing-data > div > div:nth-child(1) button {
  color: white !important;
} */
