:root {
  /* --error: #e63f36;
  --aborted: #64a2bb;
  --completed: #3d6481;
  --timeout: #82cafc;
  --running: #04143c; */

  --error: linear-gradient(326deg, #bd4f6c 0%, #d7816a 74%);
  --aborted: #7c7c7c;
  --completed: linear-gradient(195deg, #66bb6a, #43a047);
  --timeout: linear-gradient(195deg, #ffa726, #fb8c00);
  --running: linear-gradient(195deg, #49a3f1, #1a73e8);
  --IdleTimeout: linear-gradient(195deg, #49a3f1, #1a73e8);
  --pass: linear-gradient(195deg, #66bb6a, #43a047);
  --fail: linear-gradient(326deg, #bd4f6c 0%, #d7816a 74%);
  --null: rgb(170, 170, 170);
  --skipped: #7c7c7c;
  /* --null:linear-gradient(326deg, #aa9fa2 0%, #494544 74%); */
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.header-right {
  gap: 1rem;
}

.page-card {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.page-panel {
  height: 4.7rem;
  width: 97%;
  margin: auto;
}

.header-scroll h6 {
  color: white !important;
}
.header-scroll span {
  color: white !important;
}
.header-scroll svg {
  color: white !important;
}

.webdriverURL {
  padding: 1.2rem !important;
  display: flex;
  padding: 10px !important;
  border-radius: 5px;
  justify-content: space-between;
  background: white !important;
  margin: 14px;
  box-shadow: rgba(50, 50, 93, 0.25) 10px 10px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 10px 20px 16px -18px inset !important;
  cursor: pointer;
  color: #344767;
}

.copy_icon {
  cursor: pointer;
}
.webdriver_copy_icon {
  cursor: pointer;
  margin: auto;
}

.show-all:hover {
  background-color: rgb(209, 208, 208);
  border-radius: 5px;
  padding: 0rem 1rem;
}

.non-empty-cell {
  display: inline-block;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 100%;
  vertical-align: middle !important;
}
