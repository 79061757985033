.command_body {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.code_container {
  display: flex;
  gap: 1rem;
}

.command_section {
  padding-bottom: 1rem;
}

.command_section > h6 {
  padding-bottom: 0.5rem;
}

.scroll_container {
  display: flex;
  margin: 0px;
  background-color: #f1eff6 !important;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 1rem;
  gap: 10px;
  border-radius: 5px;
  width: 100%;
  height: fit-content;
  padding: 0.4rem;
}

.scroll_container > p {
  overflow: hidden;
}

.scroll_container > p:hover {
  overflow: auto;
}

.copyIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem;
}

.copyIcon > svg {
  width: 35px;
  height: 30px;
}
